<template>
  <section id="settings-profile">
    <h2 class="pb-2">{{ $t('profile') }}</h2>
    <b-row class="row-wide"  v-if="user && $store.state.user">
      <b-col lg="3" class="mb-md-0 mb-5">
        <div class="position-relative text-md-left text-center profile-image-wrapper">
          <LetterIcon :obj="$store.state.user"
                      :title="$store.state.user.name"
                      class="profile-img rounded-circle"/>
          <label for="photocam"
                 class="camera-icon btn-white btn-themed"
                 v-if="!isStudent">
            <CameraSvg/>
          </label>
          <a class="delete-photo btn-white btn-themed"
             v-if="$store.state.user.image && !isStudent"
             @click="deletePhoto">
            <CloseSvg/>
          </a>
          <input type="file" accept="image/*" class="d-none" id="photocam"  @change="processFile($event)"/>
        </div>
      </b-col>
      <b-col lg="9">
        <div v-if="user">
          <h3 class="mb-3">{{ $t('general_information') }}</h3>
          <InputGroup :label="$t('name')"
                      class="default"
                      :readonly="isStudent"
                      v-model="user.name"/>
          <InputGroup :label="$t('workplace')"
                      class="default"
                      :readonly="isStudent"
                      v-model="user.workplace"/>
          <h3 class="mb-3 mt-5">{{ $t('contacts') }}</h3>
          <InputGroup :label="$t('email')"
                      class="default"
                      :readonly="true"
                      v-model="user.email"/>
          <InputGroup :label="$t('phone')"
                      class="default"
                      :readonly="isStudent"
                      v-model="user.phone"/>
          <h3 class="mb-3 mt-5">{{ $t('settings') }}</h3>
          <SelectGroup class="default" :floatinglabel="true"
                       :key="'select-time-'+$i18n.locale"
                       v-model="user.timezone"
                       :options="timezones"
                       title="title"
                       :label="$t('timezone')"/>
          <SelectGroup class="default"
                       :key="'select-lang-'+$i18n.locale"
                       :floatinglabel="true"
                       v-model="user.interface_lang"
                       :readonly="!!(user.school && user.school.school_lang)"
                       :options="languages"
                       :label="$t('interface_language')"/>
          <SelectGroup class="default"
                       v-if="canChangeSchoolLanguage"
                       :key="'school-lang'+$i18n.locale"
                       :floatinglabel="true"
                       v-model="user.school_lang"
                       :options="languages"
                       :label="$t('school_language')"/>
          <SelectGroup class="default"
                       v-if="!canChangeSchoolLanguage && user && user.school"
                       :key="'school-u-lang'+$i18n.locale"
                       :readonly="true"
                       :floatinglabel="true"
                       v-model="user.school.school_lang"
                       :options="languages"
                       :label="$t('school_language')"/>
          <SelectGroup v-if="$store.state.user.primary_role_id != 2"
                       class="default"
                       :key="'select-cur-'+$i18n.locale"
                       :floatinglabel="true"
                       :translate="false"
                       v-model="user.currency"
                       :options="currencies"
                       :label="$t('currency')"/>
          <CheckboxGroup label="Linkchat iframe"
                         v-if="canChangeSchoolLanguage && false"
                         v-model="user.linkchat"
                         fid="linkchat"/>
          <CheckboxGroup label="Mirotalk iframe"
                         v-if="canChangeSchoolLanguage && false"
                         v-model="user.mirotalk"
                         fid="mirotalk"/>
          <div class="mt-5 text-right">
            <b-button class="btn-themed"
                      :disabled="!valid"
                      @click="save">
              {{ $t('save') }}
            </b-button>
          </div>

          <h3 class="mb-3 mt-5">{{ $t('change_password') }}</h3>
          <InputGroup type="password"
                      :label="$t('old_password')"
                      class="default"
                      v-model="oldpassword"/>
          <InputGroup type="password"
                      :label="$t('password')"
                      class="default"
                      v-model="password"/>
          <InputGroup type="password"
                      :label="$t('password_confirm')"
                      class="default"
                      v-model="password_confirm"/>
          <div class="text-danger small"
               v-if="password_confirm && password != password_confirm">
            {{ $t('passwords_do_not_match') }}
          </div>
          <div class="mt-5 text-right">
            <b-button class="btn-themed"
                      :disabled="!valid"
                      @click="changePassword">
              {{ $t('change') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import EventBus from '@/services/event-bus'
import InputGroup from "@/views/parts/general/form/InputGroup"
import SelectGroup from "@/views/parts/general/form/SelectGroup"
import CheckboxGroup from "@/views/parts/general/form/CheckboxGroup"
import { UsersService } from '@/services/api.service'
import CameraSvg from '@/assets/svg/photocamera.svg'
import CloseSvg from '@/assets/svg/trash.svg'
import moment from 'moment-timezone'
import LangHelper from "@/services/lang.helper"
import CurrencyHelper from "@/services/currency.helper"
import AuthService from "@/services/auth.helper"
import LetterIcon from '@/views/parts/general/LetterIcon'

export default {
  components: {
    InputGroup,
    CameraSvg,
    SelectGroup,
    CheckboxGroup,
    LetterIcon,
    CloseSvg
  },
  data() {
    return {
      valid: true,
      user: null,
      oldpassword: null,
      password: null,
      password_confirm: null,
      image: null,
      imageData: null,
      languages: LangHelper.interfaceLangs(),
      timezones: [],
      currencies: CurrencyHelper.currencies()
    }
  },
  computed: {
    canChangeSchoolLanguage() {
      return [3,4].includes(this.$store.state.user.primary_role_id)
    },
    isStudent() {
      return [2].includes(this.$store.state.user.primary_role_id)
    }
  },
  mounted() {
    this.timezones = LangHelper.timezonesList()
    EventBus.$emit('breadcrumbs',[
      { to: '/settings', title: this.$t('settings') },
      { to: '/settings/profile', title: this.$t('profile') },
    ])
    UsersService.get().then(res => {
      this.user = res.data.data
      if(!this.user.timezone) {
        this.user.timezone = moment.tz.guess()
      }
      // Activate
      this.user.linkchat = true;
      this.user.mirotalk = true;
      this.$store.commit('saveUser', { user: this.user, vm: this } )
    })
  },
  methods: {
    save() {
      UsersService.update(this.user).then(res => {
        this.user = res.data.data
        this.$store.commit('saveUser', { user: this.user, vm: this } )
      })
    },
    changePassword() {
      UsersService.changePassword({ old_password: this.oldpassword, new_password: this.password }).then(res => {
        let r = this.apiResponse(res)
        if(r.success) {
          this.user = r.data
          this.$success(this.$t('password_changed'))
          this.oldpassword = null
          this.password = null
          this.password_confirm = null
          if(r.data.api_token) {
            AuthService.login({
              expires_in: 43200*60,
              access_token: r.data.api_token,
              refresh_token: r.data.api_token,
            }, r.data)
            this.$store.commit('saveUser', { user: r.data, vm: this } )
          }
        } else {
          this.$error(r.data)
        }
      }).catch(err => {
        this.$error(err)
      })
    },
    processFile(event) {
      this.image = event.target.files[0]
      let formData = new FormData()
      formData.append('image', this.image)
      UsersService.changeAvatar(formData).then(res => {
        this.user = res.data.data
        this.$store.commit('saveUser', { user: this.user, vm: this } )
      })
    },
    deletePhoto() {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        let formData = new FormData()
        formData.append('delete', 1)
        UsersService.changeAvatar(formData).then(res => {
          this.user = res.data.data
          this.$store.commit('saveUser', { user: this.user, vm: this } )
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.profile-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  box-shadow: 0px 4px 4px rgba(105, 114, 121, 0.25);
}
.camera-icon, .delete-photo {
  position: absolute;
  right: 0;
  border-radius: 100%;
  padding: 0;
  width: 50px;
  height: 50px;
  line-height: 46px;
  cursor: pointer;
}
.camera-icon {
  bottom: 0;
}
.delete-photo {
  top: 0;
  display: none;
  svg {
    width: 20px;
    height: 20px;
    fill: $theme-red;
  }
}
.profile-image-wrapper:hover {
  .delete-photo {
    display: inline-block;
  }
}

@media(max-width: 768px) {
  .profile-img {
    width: 90px;
    height: 90px;
  }
  .camera-icon {
    right: 50%;
    width: 40px;
    height: 40px;
    line-height: 36px;
    margin-right: -50px;
    top: 50px;
  }
}
</style>


